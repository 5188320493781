import React from "react"
import styled from "styled-components"

import WordCard, { WordProps } from "./WordCard"
import { getLoves } from "../utils/loves"

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.background};
  &.empty {
    margin-top: 100px;
    text-align: center;
  }
`

const Highlight = styled.span`
  color: ${({ theme }) => theme.purple.primary};
  font-weight: 600;
`

const Loves = () => {
  const loves = getLoves()
  if (!loves.length) {
    return (
      <Wrapper className="empty">
        You don't have any <Highlight>FAVES</Highlight>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {loves
        .sort((a: WordProps, b: WordProps) => a.word.localeCompare(b.word))
        .map((word: WordProps) => (
          <WordCard {...word} key={word.id} />
        ))}
    </Wrapper>
  )
}

export default Loves
