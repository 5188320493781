import React from "react"
import styled, { ThemeProvider } from "styled-components"
import "normalize.css"

import SEO from "../components/seo"
import Title from "../components/Title"
import Footer from "../components/Footer"
import ViewController from "../components/ViewController"
import theme from "../styles/theme"

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.background};
  display: flex;
  height: 100%;
  flex-direction: column;
`

const TopFold = styled.div`
  background-color: white;
  text-align: center;
  width: 100%;
`

const IndexPage = () => (
  <ThemeProvider theme={theme}>
    <Wrapper>
      <SEO title="A Dictionary For Ants" />
      <TopFold>
        <Title />
      </TopFold>
      <ViewController />
      <Footer />
    </Wrapper>
  </ThemeProvider>
)

export default IndexPage
