import React, { useState } from "react"
import styled from "styled-components"

const Header = styled.div`
  font-style: italic;
  font-weight: 800;
  text-transform: uppercase;
`
const TitleElement = styled.div`
  cursor: pointer;
  font-size: 24px;
  font-style: italic;
  font-weight: 800;
  text-transform: uppercase;
`

const Wrapper = styled.div`
  padding: 25px 0px;
`

const Title = () => {
  const [showHeader, setShowHeader] = useState(false)
  const mainTitle = "A Dictionary For Ants"
  const title = showHeader ? `${"..."}${mainTitle}${"?!"}` : mainTitle
  const handleTitleClick = () => setShowHeader(true)
  return (
    <Wrapper>
      {showHeader && <Header>What is this?</Header>}
      <TitleElement onClick={handleTitleClick}>{title}</TitleElement>
    </Wrapper>
  )
}

export default Title
