import React from "react"
import styled from "styled-components"

const Bold = styled.span`
  color: ${({ theme }) => theme.purple.primary};
  font-weight: 600;
  text-transform: uppercase;
`

const Container = styled.div`
  background-color: inherit;
  margin-top: auto;
  font-size: 11px;
  padding-bottom: 25px;
  padding-top: 30px;
  text-align: center;
`

const Footer = () => (
  <Container>
    Powered by <Bold>lift</Bold>, <Bold>build</Bold>, and Merriam-Webster
  </Container>
)

export default Footer
