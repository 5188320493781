import { WordProps } from "../components/WordCard"

const FAVES = "ant-loves"

const getLoves = () => {
  const loves = localStorage.getItem(FAVES)
  return loves ? JSON.parse(loves) : []
}

const addToLoves = (word: WordProps) => {
  const existingLoves = getLoves()
  const newLoves = JSON.stringify([...existingLoves, word])
  localStorage.setItem(FAVES, newLoves)
}

const removeFromLoves = (id: string) => {
  const existingLoves = getLoves()
  const newLoves = JSON.stringify(
    existingLoves.filter(
      ({ id: existingId }: { id: string }) => id !== existingId
    )
  )
  localStorage.setItem(FAVES, newLoves)
}

export { addToLoves, getLoves, removeFromLoves }
