import React, { useEffect, useState } from "react"

import Button from "../../ui/Button"
import { addToLoves, getLoves, removeFromLoves } from "../../utils/loves"
import FullscreenMessage from "../FullscreenMessage"
import CardTitle from "./CardTitle"
import { ActionContainer, Container, Def, FaveButton, Fl, Word } from "./styles"

export type WordProps = {
  defs: string[]
  fl: string
  id: string
  offensive: boolean
  word: string
}
type WordCardProps = WordProps & {
  handleNewWord?: (event: any) => void
}

const WordCard = ({
  defs,
  fl,
  handleNewWord,
  id,
  offensive,
  word,
}: WordCardProps) => {
  const [love, setLove] = useState(false)
  const [showCopied, setShowCopied] = useState(false)
  const [render, setRender] = useState(0)
  useEffect(() => {
    const loves = getLoves()
    const isLove = loves.some(
      ({ id: existingId }: { id: string }) => existingId === id
    )
    setLove(isLove)
  }, [render, word])

  const handleRemoveLove = (id: string) => {
    removeFromLoves(id)
    setRender(render => render + 1)
  }
  const handleAddLove = (word: WordProps) => {
    addToLoves(word)
    setRender(render => render + 1)
  }

  const LoveButton = () =>
    love ? (
      <FaveButton className="filled" onClick={() => handleRemoveLove(id)}>
        FAVED
      </FaveButton>
    ) : (
      <FaveButton
        onClick={() => handleAddLove({ defs, fl, id, offensive, word })}
      >
        FAVE
      </FaveButton>
    )

  const bite = () => {
    const elements = ["p", "h1", "h2", "h3", "h4", "h5", "h6", "button"]
    elements.forEach(element => {
      const els = [...document.querySelectorAll(element)]
      els.forEach(el => {
        const innterText = el.innerHTML
        if (!innterText) return
        const newText = el.innerHTML
          .split(" ")
          .map(() => "bite")
          .join(" ")
        el.innerHTML = newText
      })
    })
  }

  const handleShare = () => {
    setShowCopied(true)
    navigator.clipboard.writeText(`${window.location.origin}?word=${word}`)
    setTimeout(() => {
      setShowCopied(false)
    }, 1500)
  }

  return !!word ? (
    <>
      <Container>
        <Word>
          <CardTitle word={word} />
        </Word>
        <Fl>{fl}</Fl>
        {defs.map(def => (
          <Def key={def}>{def}</Def>
        ))}
        <ActionContainer>
          <div>
            <LoveButton />
            <Button onClick={handleShare}>Share</Button>
          </div>
          {!!handleNewWord && <Button onClick={handleNewWord}>DIG</Button>}
        </ActionContainer>
      </Container>
      {showCopied && <FullscreenMessage message="copied" />}
    </>
  ) : null
}

export default WordCard
