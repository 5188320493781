import React from "react"

type CardTitleProps = {
  word: string
}

const CardTitle = ({ word }: CardTitleProps) => {
  const createMarkup = () => {
    const markup = word.replace(/ant/gi, "<span class='highlight'>ant</span>")
    return { __html: markup }
  }

  return <div dangerouslySetInnerHTML={createMarkup()} />
}

export default CardTitle
