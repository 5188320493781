import styled from "styled-components"

const Button = styled.button.attrs({ type: "button" })`
  background-color: white;
  border-radius: 5px;
  border: ${({ theme }) => `1px solid ${theme.purple.primary}`};
  color: ${({ theme }) => theme.purple.primary};
  cursor: pointer;
  font-weight: 600;
  height: 40px;
  padding: 0px 10px;
  text-transform: uppercase;
  &:hover,
  &:active,
  &.filled {
    background-color: ${({ theme }) => theme.purple.primary};
    color: white;
  }
`
export default Button
