import React, { useState } from "react"
import styled from "styled-components"
import classNames from "classnames"

import Learn from "./Learn"
import Loves from "./Loves"

enum VIEWS {
  LEARN = "learn",
  FAVES = "loves",
}

const ContentContainer = styled.div`
  margin: 0 auto;
  max-width: 640px;
  width: 100%;
`

const TabContainer = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
`

const Tab = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: 600;
  max-width: 320px;
  padding-top: 20px;
  padding-bottom: 15px;
  user-select: none;
  width: 50%;
  &.active {
    border-bottom: 5px solid ${({ theme }) => theme.purple.primary};
  }
  &:focus {
    outline: none;
  }
`

const ViewController = () => {
  const [view, setView]: [VIEWS, any] = useState(VIEWS.LEARN)
  const isLearn = view === VIEWS.LEARN
  const isLoves = view === VIEWS.FAVES
  return (
    <>
      <TabContainer>
        <Tab
          type="button"
          className={classNames({ active: isLearn })}
          onClick={() => setView(VIEWS.LEARN)}
        >
          LEARN
        </Tab>
        <Tab
          type="button"
          className={classNames({ active: isLoves })}
          onClick={() => setView(VIEWS.FAVES)}
        >
          FAVES
        </Tab>
      </TabContainer>
      <ContentContainer>
        {isLearn && <Learn />}
        {isLoves && <Loves />}
      </ContentContainer>
    </>
  )
}

export default ViewController
