import React, { useState, useEffect } from "react"
import styled from "styled-components"

type FullscreenMessageProps = {
  message: string
}

const Wrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.purple.primary};
  display: flex;
  justify-content: center;
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  transition: opacity 1.5s ease-out;
  &.fade {
    opacity: 0;
  }
`

const Message = styled.h1`
  color: white;
  text-transform: uppercase;
`

const FullscreenMessage = ({ message = "" }: FullscreenMessageProps) => {
  const [fade, setFade] = useState(false)
  useEffect(() => {
    setFade(true)
  }, [setFade])

  return (
    <Wrapper className={fade ? "fade" : ""}>
      <Message>¡{message.split("").join(" ")}!</Message>
    </Wrapper>
  )
}

export default FullscreenMessage
