import styled from "styled-components"
import Button from "../../ui/Button"

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Container = styled.div`
  background-color: white;
  border-radius: 8px;
  margin: 15px 5px;
  padding: 10px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.02), 0 2px 2px rgba(0, 0, 0, 0.02),
    0 4px 4px rgba(0, 0, 0, 0.02), 0 8px 8px rgba(0, 0, 0, 0.02);
`

const Def = styled.p`
  &::before {
    background-color: ${({ theme }) => theme.purple.primary};
    border-radius: 100%;
    content: "";
    display: inline-block;
    height: 7px;
    top: -2px;
    margin-right: 10px;
    position: relative;
    width: 7px;
  }
`

const FaveButton = styled(Button)`
  margin-right: 25px;
`

const Word = styled.h3`
  & span.highlight {
    color: ${({ theme }) => theme.purple.primary};
  }
`

const Fl = styled.p``

export { ActionContainer, Container, Def, FaveButton, Fl, Word }
