import React, { useState, useLayoutEffect } from "react"
import styled from "styled-components"
import queryString from "query-string"

import WordCard, { WordProps } from "./WordCard"
import words from "../data/filtered.json"

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.background};
`

const Learn = () => {
  const [wordList, setWordList] = useState([] as WordProps[])

  const setRandomWord = () => {
    const wordCount = words.length
    const randomIndex = Math.floor(Math.random() * wordCount)
    setWordList(list => [words[randomIndex], ...list] as WordProps[])
  }

  useLayoutEffect(() => {
    const { word: queryWord } = queryString.parse(window.location.search)
    let wordInfo = null
    if (queryWord) {
      wordInfo = words.find(({ word: wordName }) => wordName === queryWord)
    }

    wordInfo ? setWordList([wordInfo] as WordProps[]) : setRandomWord()
  }, [])

  return (
    <Wrapper>
      {wordList.map(word => (
        <WordCard {...word} handleNewWord={setRandomWord} />
      ))}
    </Wrapper>
  )
}

export default Learn
